export const navigationData = [
  {
    id: 1,
    name: 'Inicio',
    href: '/',
    children: [
      {
        id: 1,
        name: 'Unne',
        href: '/',
      },
      {
        id: 2,
        name: '¿Quiénes somos?',
        href: '/quienes-somos',
      },
    ],
  },
  {
    id: 2,
    name: 'Soy Propietario',
    children: [
      {
        id: 1,
        name: 'Quiero vender',
        href: '/soy-propietario/quiero-vender',
      },
      {
        id: 2,
        name: 'Quiero arrendar',
        href: '/soy-propietario/quiero-arrendar',
      },
    ],
  },
  {
    id: 3,
    name: 'Servicios',
    children: [
      {
        id: 1,
        name: 'Capitalizarme',
        href: '/soy-inversionista/capitalizarme',
      },
      // {
      //   id: 2,
      //   name: 'Unidades en remate',
      //   href: '/soy-inversionista/unidades-en-remate',
      // },
      // {
      //   id: 2,
      //   name: 'Unidades nuevas',
      //   href: '/soy-inversionista/unidades-nuevas',
      // },
      // {
      //   id: 3,
      //   name: 'Administración de arriendo',
      //   href: '/soy-inversionista/administracion-de-arriendo',
      // },
    ],
  },
  // {
  //   id: 4,
  //   name: 'Propiedades',
  //   children: [
  //     {
  //       id:1,
  //       name: "Propiedades",
  //       href: 'https://unnecorredores.cl/',
  //     },
  //     // {
  //     //   id: 2,
  //     //   name: 'Socios Unne',
  //     //   href: '/partnersUnne/socios-unne',
  //     // },
  //   ]
  // },
  {
    id: 5,
    name: '¿Eres corredor?',
    children: [
      {
        id: 1,
        name: 'Asociate',
        href: '/eres-corredor/asociate',
      },
      // {
      //   id: 2,
      //   name: 'Servicios legales',
      //   href: '/eres-corredor/servicios-legales',
      // },
      {
        id: 3,
        name: 'Academia Unne',
        href: '/eres-corredor/academia-unne',
      },
    ],
  },
  {
    id: 6,
    name: 'Mi cuenta',
    href: 'https://unnecorredores.cl/intranet.asp',
  },
];
